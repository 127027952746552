/* Gallery Page Custom Styling */
.gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item img {
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
    width: 100%;
    height: auto;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
  }
  
  .gallery-item:hover img {
    transform: scale(1.1);
  }
  