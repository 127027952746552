*{
  font-family: "Poppins", sans-serif;
}

body{
  background-color: black;
}

button {
  background-color: #B2984A;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  width: fit-content;
}

.head {
  background-image: url('assets/makeover_bg.png'); /* Replace with your image path */
  background-size: cover; /* Ensures the image covers the entire header */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  color: white; /* Optional: changes text color if needed */
  height: 1000px;
  max-width: 100vw;
  overflow: hidden;
  padding: 30px;
  flex-direction: column; /* Aligns navbar and hero vertically */
  align-items: center;
  justify-content: center;
}


/* Logo text styling */
.logo-text {
  font-weight: bold;
  font-size: 1rem; /* Adjust size as needed */
  color: white;
  text-decoration: none;
  text-align: center;
}

.logo-text:hover{
  color: white;
}



/* Optional: Adjust navbar text color to stand out against the background */
/* Navbar Links - Default to white */

/* Custom styles for the toggler icon */
.navbar-light .navbar-toggler {
  border-color: transparent; /* Remove border */
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='white'%3E%3Cpath stroke='white' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar{
  width: 70vw;
}

.navbar-nav{
  gap: 10px;
}
.navbar-nav .nav-link {
  color: white !important; /* Ensures links are white */
  font-size: 13px;
  font-weight: 600;
}

/* Hover Effect - Lighten on Hover */
.navbar-nav .nav-link:hover {
  color: #f0f0f0 !important; /* Slightly lighter shade on hover */
}

.navbar .btn-link{
  color: white !important;
}

.navbar .btn-link:hover{
  color: #B2984A !important;
}

/* Active Link - Yellow color */
.navbar-nav .nav-link.active {
  color: #B2984A !important; /* Yellow for active links */
}


/* Center-left alignment for hero section */
.hero-section {
  max-width: 1200px; /* Adjust width as needed */
  text-align: left; /* Left align text */
  align-self: center; /* Centers hero section horizontally */
  margin-top: auto;
  margin-bottom: auto;
  padding: 10rem 16rem;
}

/* Spacing and font adjustments */
.hero-section p,
.hero-section h1,
.hero-section h5,
.hero-section h6 {
  margin: 0 0 15px 0; /* Adds spacing between elements */
}

.hero-section h1 {
  font-size: 3.5rem; /* Adjust for main headline */
}

.hero-section h5 {
  font-size: 1.25rem; /* Adjust for subheading */
}

.hero-section h6 {
  font-size: 1rem; /* Adjust for supporting text */
}

/* Button styling */
.hero-section button {
  background-color: #B2984A;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
}

.hero-section button:hover {
  background-color: #A08040; /* Slightly darker on hover */
}


.h-about{
  margin-top: -100px;
}

.h-about .h-about-detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-weight: 600;
}

.h-service{
  background-image: url('assets/h-service.png');
  background-size: cover; /* Ensures the image covers the entire header */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  min-height: 800px;
  max-width: 100%;
  overflow: hidden;
  color: white;
}


.h-gallery{
  color: white;
}

.h-gallery .row {
  display: flex; /* Ensure all images align as flex items */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.h-gallery img {
  width: 100%; /* Make images responsive */
  height: 200px; /* Set a fixed height for all images */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.testimonials{
  color: white;
  background: #17161E;
}

.testimonials {
  overflow: hidden; /* Prevent overflow for animation */
  position: relative; /* Position relative for absolute children */
}

.testimonial-box-wrapper {
  display: flex; /* Use flexbox for horizontal alignment */
  animation: scroll 18s linear infinite; /* Animation for scrolling effect */
}

.testimonial-box {
  background: white;
  color: black;
  padding: 15px; /* Reduced padding for a smaller box */
  border-radius: 8px; /* Slightly rounded corners */
  text-align: left;
  margin: 0 30px; /* Space between boxes */
  flex: 0 0 400px; /* Fixed width for consistent sizing */
  height: auto; /* Set a fixed height for the rectangular box */
  display: flex; /* Use flexbox for inner content alignment */
}

/* Left: Profile Image */
.testimonial-box img {
  width: 80px; /* Set a fixed width for the image */
  height: 80px; /* Set a fixed height for the image */
  border-radius: 50%; /* Keep image circular */
  margin-right: 15px; /* Space between image and text */
}

.testimonial-box p{
  font-size: 12px;
}

/* Animation keyframes for circular effect */
@keyframes scroll {
  0% {
      transform: translateX(0); /* Start position */
  }
  100% {
      transform: translateX(-33.33%); /* Move left by the width of 1/3 of the total testimonial width */
  }
}

/* Optional: Pause the animation on hover */
.testimonial-box-wrapper:hover {
  animation-play-state: paused; /* Pause animation on hover */
}


/* second row testimonials */

.testimonial-box-wrapper1 {
  display: flex; /* Use flexbox for horizontal alignment */
  animation: scroll1 10s linear infinite; /* Animation for scrolling effect */
}

.testimonial-box1 {
  background: white;
  color: black;
  padding: 15px; /* Reduced padding for a smaller box */
  border-radius: 8px; /* Slightly rounded corners */
  text-align: left;
  margin: 0 30px; /* Space between boxes */
  flex: 0 0 400px; /* Fixed width for consistent sizing */
  height: auto; /* Set a fixed height for the rectangular box */
  display: flex; /* Use flexbox for inner content alignment */
}

/* Left: Profile Image */
.testimonial-box1 img {
  width: 80px; /* Set a fixed width for the image */
  height: 80px; /* Set a fixed height for the image */
  border-radius: 50%; /* Keep image circular */
  margin-right: 15px; /* Space between image and text */
}

.testimonial-box1 p{
  font-size: 12px;
}

/* Animation keyframes for circular effect */
@keyframes scroll1 {
  0% {
      transform: translateX(-33.33%); /* Start position */
  }
  100% {
      transform: translateX(0); /* Move left by the width of 1/3 of the total testimonial width */
  }
}

/* Optional: Pause the animation on hover */
.testimonial-box-wrapper1:hover {
  animation-play-state: paused; /* Pause animation on hover */
}


.h-contact{
  background-image: url('assets/contact_image_bg.png');
  background-position: center;
  background-size: cover;
  padding: 40px 0;
}


.h-contact p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

#contactForm .form-label {
  font-weight: 500;
}

#contactForm .form-control {
  border-radius: 6px;
}

#contactForm .btn-primary {
  background-color: #b2984a;
  border-color: #b2984a;
}

#contactForm .btn-primary:hover {
  background-color: #a08539;
  border-color: #a08539;
}


.footer {
  background-color: #283646;
  color: #fff;
}



.footer-logo {
  max-width: 100px; /* Adjust the logo size */
}

.footer h5 {
  font-weight: bold;
  margin-bottom: 15px;
  color: #9E8742;
}

.footer ul {
  padding: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: white;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.footer p {
  font-size: 14px;
  margin: 0;
}


/* About page style */

.About-section{
  color: white;
  background: #17161E;
}

.About-section h1{
  font-weight: bold;
}


.About-section p{
  font-weight: 600;
}

.whychoose{
  background: #17161E;
}

.whychoose .makeup{
  background: black;
}

.whychoose .makeup h4{
  color: #B2984A;
  font-weight: bold;
}

.whychoose .makeup-img{
  background-image: url('assets/whychoose_img.png');
  background-position: center;
  background-size: cover;
  color: white;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  text-align: end;
}

.whychoose .makeup-img h2{
  font-weight: bold;
}

/* Service page style */

.service-section{
  color: white;
  background: #17161E;
}

.service-section h1{
  font-weight: bold;
}


.service-section p{
  font-weight: 600;
}

.nextcom h3{
  color: white;
  font-weight: bold;
}

.service-last{
  background-image: url('assets/service-last.png');
  background-position: center;
  background-size: cover;
}

/* Contact page style */

.contact-section{
  background-image: url(assets/h-service.png);
  background-position: center;
  background-size: cover;
  min-width: 100%;
}

.down-arrow-animation {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-10px);
  }
  60% {
      transform: translateY(-5px);
  }
}
